<template>
  <div class="flex md12 xs12">
    <vue-context
      ref="menu"
    >
      <li class="pl-3">
        <i
          class="va-icon vuestic-iconset vuestic-iconset-user mx-1"
        /> {{ rightClickData.displayName }}
      </li>
      <li>
        <a
          href="#"
          @click.prevent="removeAdmin"
        >Remove admin</a>
      </li>
      <li>
        <a
          href="#"
          @click.prevent="$router.push({name:'edituser', params:{'uid':rightClickData.uid}})"
        >Go to Profile</a>
      </li>
    </vue-context>

    <va-modal
      v-model="addAdminModal"
      size="large"
      class="flex xs12"
      :title="'Add Organization Admin'"
      :ok-text=" $t('modal.confirm') "
      :cancel-text=" $t('modal.cancel') "
      @ok="addOrgAdmins"
      :close-on-ok="false"
    >
      Name of the Admin:
      <va-input
        @input="search"
        :placeholder="'Search by name or email'"
        v-model="adminName"
      />
      <div v-if="searching">
        Searching...
      </div>
      <div v-else>
        <va-data-table
          :fields="userFields"
          :data="foundUsers"
          :loading="searching"
          :per-page="5"
          clickable
          :style="{ minHeight: '438px' }"
          @row-clicked="(row)=>{ addAdmin(row) }"
          hoverable
        >
        <template slot-scope="props" slot="profile">
          <div class="d-flex justify-center">
            <va-avatar>
              <img
                class="cursor-pointer"
                :src="props.rowData.photoURL ? props.rowData.photoURL : 'https://nihss3.com/assets/defaultProfilePic.png'"
                :alt="props.rowData.displayName"
                v-on:error="onImageError"
                onerror="return true;"
              />
            </va-avatar>
            <span class="ml-2">{{ props.rowData.displayName }}</span>
            <va-icon
                  v-if="props.rowData.starred"
                  name="ml-2 pt-1 fa fa-star"
                  color="warning"
            />
          </div>
        </template>
      
      </va-data-table>
      </div>
    </va-modal>
   


    <va-card title="Edit Organization">
        <va-card
          class="flex lg12 xs12"
        >
          <form @submit.prevent="onsubmit">
            <div class="row">
              <div class="flex xs12 sm12 lg2 imgContainer">
                <div class="imgContainer">
                  <img
                    class="justify—center flex bigAvatar"
                    @click="changeImage()"
                    :src="org.profilePictureUrl ? org.profilePictureUrl : 'https://nihss.plus/assets/defaultProfilePic.png'"
                  >
                </div>
              </div>
              <div
                class="flex xs12 sm12 lg10"
              > 
                <div>
                  <va-input
                    v-model="org.name"
                    :placeholder="'Apex Innovations'"
                    :label="'Org Name'"
                  >
                  <va-icon
                        slot="prepend"
                        color="gray"
                        name="fa fa-user"
                  />
                  </va-input>
                  <va-input
                    v-if="editing"
                    v-model="cleanSecret"
                    :placeholder="'Secret'"
                    maxlength="8"
                    :label="'Org Secret'"
                    @blur="editing = false"
                  >
                  <va-icon
                        slot="prepend"
                        color="gray"
                        name="fa fa-lock"
                  />
                  </va-input>
                  <va-input
                    v-else-if="!editing && !this.secretChanged()"
                    v-model="org.secret"
                    :placeholder="'Secret'"
                    maxlength="8"
                    :label="'Org Secret'"
                    
                    @click="editing = true"
                  >
                  <va-icon
                        slot="prepend"
                        color="gray"
                        name="fa fa-lock"
                  />
                  </va-input>
                  <va-input
                    v-else
                    v-model="cleanSecret"
                    :placeholder="'Secret'"
                    maxlength="8"
                    :label="'Org Secret'"
                    @click="editing = false"
                  >
                  <va-icon
                        slot="prepend"
                        color="gray"
                        name="fa fa-lock"
                  />
                  </va-input>

                </div>
                <va-toggle
                  v-model="org.active"
                  @input="changeOrgStatus()"
                  label="Active Organization"
                />
                <va-button @click="saveData">
                  <i
                    class="fa fa-save pr-2"
                    style="color: rgb(255, 255, 255);"
                  /> Save Changes
                </va-button>
                <va-button @click="copyORGSecret()">
                <i
                  class="va-icon vuestic-iconset vuestic-iconset-comments pr-2"
                  style="color: rgb(255, 255, 255);"
                /> Copy Join Code
              </va-button>
                <va-button @click="organizationDetailsPage()" class="rounded-sm">
                  <i
                    class="fa fa-arrow-right pr-2"
                    style="color: rgb(255, 255, 255);"
                  /> Show More Info
                </va-button>
              </div>
             
            </div>
          </form>
        </va-card>


   
        <va-data-table
            :fields="adminFields"
            :data="admins"
            :loading="loading"
            :per-page="5"
            hoverable
          >
          <template slot-scope="props" slot="profile">
            <div class="d-flex justify-center">
              <va-avatar>
                <img
                  :src="props.rowData.photoURL ? props.rowData.photoURL : 'https://nihss3.com/assets/defaultProfilePic.png'"
                  :alt="props.rowData.displayName"
                  v-on:error="onImageError"
                  onerror="return true;"
                />
              </va-avatar>
              <div>
                <p class="ml-2">{{ props.rowData.displayName }}</p> 
                <p class="ml-2">Birth: {{ formatBirthDate(props.rowData.dtbirth) }}</p>
              </div>
            </div>
          </template>
          <template slot-scope="props" slot="actions">
            <va-button
              class="text-white rounded py-1 px-2 flex items-center"
              color="info"
              @click.stop="goToEditUser(props.rowData)"
            >
              User Details
            </va-button>
            <va-button
              class="text-white rounded py-1 px-2 flex items-center"
              color="danger"
              @click.stop="removeAdminBTN(props.rowData)"
            >
              Remove Admin
            </va-button>
          </template>
        </va-data-table>

      <div class="row">
        <va-button @click="addAdminModal = true">
            <i
              class="va-icon vuestic-iconset vuestic-iconset-user pt-1 pr-2"
              style="color: rgb(255, 255, 255);"
            /> Add Org Admin
          </va-button>
        <va-button
          @click="createOrgUsers()"
          icon="fa fa-users "
        >
          Create Users/Admins
        </va-button>
        <va-button @click="listProcedures()">
            <i
              class="va-icon vuestic-iconset vuestic-iconset-comments pt-1 pr-2"
              style="color: rgb(255, 255, 255);"
            /> User Procedures
          </va-button>
        <va-button @click="goToForms">
            <i
              class="va-icon vuestic-iconset vuestic-iconset-files pt-1 pr-2"
              style="color: rgb(255, 255, 255);"
            /> Orders
          </va-button>
        <va-button
          @click="downloadManifest"
          icon="fa fa-download "
        >
          Download Manifest
        </va-button>
      </div>
    </va-card>
  </div>
</template>
<script>

import firebase from 'firebase';
import {debounce} from 'lodash';
import generateManifest from '../../scripts/generateManifest';
import axios from '../../scripts/interceptor';

export default {
  data() {
    return {
      loading: true,
      staticName: '',
      addAdminModal: false,
      addDeptModal: false,
      adminName: '',
      org: {},
      cleanSecret: '',
      editing: false,
      searching: false,
      admins: [],
      foundUsers: [],
      rightClickData: {},
      menuIsOpen: false,
    };
  },
  computed: {
    fields() {
      return [{
        name: 'name',
        title: this.$t('tables.headings.name'),
        sortField: 'name',
        width: '50%',
      }, {
        name: 'admins',
        title: 'Admin Count',
        sortField: 'admins',
        width: '25%',
      }, {
        name: 'users',
        title: 'User Count',
        sortField: 'users',
        width: '25%',
      }];
    },
    userFields(){
      return [{
        name: '__slot:profile',
        title: 'Name',
      },
      {
        name: 'email',
        title: 'Email',
      }]
    }, 
    adminFields() {
      return [{
        name: '__slot:profile',
        title: this.$t('tables.headings.name'),
        width: '33%',
      }, {
        name: 'email',
        title: 'Email',
        sortField: 'email',
        width: '33%',
      },
      {
        name: '__slot:actions',
        title: 'Actions',
        width: '33%',
      }];
    },
  },
  mounted() {
    this.getOrg();
  },
  methods: {
    
/**
 * @ATTENTION - THIS FUNCTION IS NOT READY YET, STILL NEEDS TO BE TESTED AND IMPLEMENTED
 * @LASTUPDATE - 03-14-2023 (MM-DD-YYYY)
*/
changeImage() {
  const orgUid = this.$route.params.uid
  this.$swal.fire({
    title: 'Change Organization Picture',
    input: 'file',
    inputAttributes: {
      accept: 'image/*',
      'aria-label': 'Upload an image'
    },
    showCancelButton: true,
    confirmButtonText: 'Upload',
    showLoaderOnConfirm: true,
    preConfirm: (file) => {
      const storageRef = firebase.storage().ref(`organizationImages/${orgUid}/profilePic.jpg`)
      //save in storage
      return storageRef.put(file).then((snapshot) => {
        //get url
        return storageRef.getDownloadURL().then((url) => {
          return url
        })
      })
   
    },
    allowOutsideClick: () => !this.$swal.isLoading()
  }).then((result) => {
    if (result.isConfirmed) {
      const imageUrl = result.value
      firebase.firestore().collection('organizations').doc(orgUid).update({
        profilePictureUrl: imageUrl
      }).then(() => {
        this.$swal.fire({
          icon: 'success',
          title: 'Image uploaded successfully'
        })
      }).catch((error) => {
        this.$swal.fire({
          icon: 'error',
          title: 'Error',
          text: `Failed to update organization image: ${error}`
        })
      })
    }
  })
  },
    listProcedures() {
      this.$router.push({name: 'org-list-procedure', params: {uid: this.$route.params.uid}});
    },
    createOrgUsers() {
      this.$router.push({name: 'org-create-users', params: {uid: this.$route.params.uid}});
    },
    goToForms() {
      this.$router.push({name: 'order-forms', params: {uid: this.$route.params.uid}});
    },
    downloadManifest() {
      generateManifest(this.org.name, this.org.secret);
    },
    addAdmin(rowData){
      rowData.starred = !rowData.starred;
    },
    async addOrgAdmins() {
      for await(let user of this.foundUsers ){
        if (user.starred) {
          //add this.org.secret to collection "organizations" inside the document "this.$route.params.uid" from users
          await firebase.firestore().collection('users').doc(user.objectID).collection('Organizations').doc(this.$route.params.uid).set({
            organization: firebase.firestore().collection('organizations').doc(this.$route.params.uid),
            secret: this.org.secret ? this.org.secret : null,
            orderSecret: null,
            joinDate: firebase.firestore.Timestamp.now(),
          }, {merge: true})
          firebase.firestore().collection('organizations').doc(this.$route.params.uid).update({
              admins: firebase.firestore.FieldValue.arrayUnion(firebase.firestore().collection('users').doc(user.objectID)),
          }).then(()=>{
            this.getOrg();
            //close modal
            this.addAdminModal = false;
          });
        }
      };
    },
    search: debounce(async function(name) {
      this.searching = true;
      try {
        const result = await axios.post(`/api/admin/searchUser`, {
          search: this.adminName,
        });
        const foundUsers = [...result.data?.data?.hits]; // Copia os resultados da busca
        foundUsers.forEach(user => {
          user.starred = false; // Adiciona a propriedade "starred" a cada objeto
        });
        this.foundUsers = foundUsers;
      } catch (error) {
        console.log('Erro ao buscar usuários:', error);
      }
      this.searching = false;
    }, 250),
    goToEditUser(user) {
      this.$router.push({name: 'edituser', params: {uid: user.uid}});
    },
    formatBirthDate(date) {
      return date ? new Date(date).toLocaleDateString() : '';
    },
    getOrg() {
      this.loading = true;
      this.admins = [];
      firebase.firestore().collection('organizations').doc(this.$route.params.uid).onSnapshot(async (orgSnapshot) => {
        const data = orgSnapshot.data();
        if (data) {
          this.staticName = data.name.toString();
          this.cleanSecret = data.secret ? (data.secret.toString()).replace('OG', '') : '';

          this.org = data;
          //Use promises to get all admins
          const adminPromises = this.org.admins.map((admin) => {
            return admin.get().then((snapshot) => {
              const adminData = snapshot.data();
              adminData.uid = admin.id;
              return adminData;
            });
          });
          // Wait for all promises to resolve
          this.admins = await Promise.all(adminPromises);
          this.loading = false;
        }
      });
    },
    onImageError(event) {
      event.target.src = 'https://nihss3.com/assets/defaultProfilePic.png';
    },
    toggleStar(user) {
      user.starred = !user.starred;
    },
    changeOrgStatus() {
      this.$swal({
          title: 'Loading...',
          html: 'Please, wait while we update the organization',
          allowOutsideClick: false,
          showConfirmButton: false,
          onBeforeOpen: () => {
          this.$swal.showLoading();
          },
      });
      firebase.firestore().collection('organizations').doc(this.$route.params.uid).update({
        active: this.org.active,
      }).then(() => {
        this.$swal.fire({
          icon: 'success',
          title: 'Organization Status Updated',
          html: `Organization is now ${this.org.active ? 'active' : 'inactive'}`,
          showConfirmButton: true,

        });
      }).catch((error) => {
        this.$swal.fire({
          icon: 'error',
          title: 'Error',
          text: `Failed to update organization status: ${error}`,
        });
      });
    },
    organizationDetailsPage() {
      this.$router.push({name: 'organization-details', params: {uid: this.$route.params.uid}});
    },
    validateSecret(secret){
      //check if contains 8 digits, just letter and numbers, no special characters and no spaces
      if(!secret.match(/^[a-zA-Z0-9]{8}$/)){
        this.$swal.fire({
          icon: 'error',
          title: 'Invalid Secret',
          text: 'Secret must contain 8 digits, just letters and numbers, no special characters and no spaces',
        });
        throw new Error('Invalid Secret');
      }
    },
    async updateSecretInOrderForms(orderForms, cleanSecret) {
      const updatePromises = orderForms.data.data.map((form) =>
        firebase
          .firestore()
          .collection("users")
          .doc(form.id)
          .collection("Organizations")
          .doc(form.organizationID)
          .update({
            secret: "OG" + cleanSecret,
          })
      );

      return Promise.all(updatePromises);
    },
    secretChanged(){
      return this.cleanSecret !== (this.org.secret ? this.org.secret.toString().replace("OG", "") : "")
    },
    async saveData() {
      this.loading = true;
      this.validateSecret(this.cleanSecret)

      let cacheSecret = this.org.secret
      //check if secret was changed
      if (this.secretChanged()) {
        //check if secret is already in use
        const response = await firebase
          .firestore()
          .collection("organizations")
          .where("secret", "==", "OG" + this.cleanSecret)
          .get();
        if (!response.empty) {
          this.$swal.fire({
            icon: "error",
            title: "Secret already in use",
            text: "Please choose another secret",
          });
          throw new Error("Secret already in use");
        }
        const orderForms = await axios.post(`/api/admin/updateRelatedOrderForms`, {
          organizationID: this.$route.params.uid,
          secret: this.org.secret,
        })
        // Update the secret in all orderForms
        await this.updateSecretInOrderForms(orderForms, this.cleanSecret);
      }
      const orgData = firebase.firestore().collection('organizations').doc(this.$route.params.uid);
      orgData.update({
        name: this.org.name,
        secret: 'OG' + this.cleanSecret,
      }).then(()=>{
        this.loading = false;
        this.getOrg();
        this.$swal.fire({
          icon: 'success',
          title: 'Organization Updated!',
          html: 'Organization data has been updated, your changes should be visible now',
          timer: 1000,
        });
      });
    },
    removeAdminBTN(user){
      this.$swal.fire({
        icon: 'warning',
        title: 'Are you sure?',
        html: 'Do you want to remove ' + user.displayName + ' as an org admin?',
        showCancelButton: true,
        confirmButtonText: 'Remove Admin'
      }).then(result=>{
        if(result.isConfirmed)
        {
          firebase.firestore().collection('organizations').doc(this.$route.params.uid).update({
            admins: firebase.firestore.FieldValue.arrayRemove(firebase.firestore().collection('users').doc(user.uid))
          }).then(()=>{
            this.$swal.fire({
              icon:'success',
              title:'Admin Removed!',
              timer:1000
            })
            this.getOrg()
          })
        }
      })
    },
    removeAdmin(){
      this.$swal.fire({
        title: 'Do you want to remove ' + this.rightClickData.displayName + ' as an org admin?',
        showCancelButton: true,
        confirmButtonText: 'Remove Admin'
      }).then(result=>{
        if(result.isConfirmed)
        {
          firebase.firestore().collection('organizations').doc(this.$route.params.uid).update({
            admins: firebase.firestore.FieldValue.arrayRemove(firebase.firestore().collection('users').doc(this.rightClickData.uid))
          }).then(()=>{
            this.$swal.fire({
              icon:'success',
              title:'Admin Removed! (no need to save)',
              timer:1000
            })
            this.getOrg()
          })
        }
      })
    },
    setRightClickData(data){
      this.rightClickData = data
    },
    copyORGSecret(){
      this.$swal.fire({
        title: 'Copy Join Code',
        html: 'The Organization Secret for this Organization is: <br> <b>' + this.org.secret + '</b>',
        showConfirmButton: true,
        confirmButtonText: 'Copy on Clipboard',
        showCancelButton: true,
        cancelButtonText: 'Close',
      }).then(result=>{
        if(result.isConfirmed)
        {
          navigator.clipboard.writeText(this.org.secret)
          this.$swal.fire({
            icon:'success',
            title:'Join Code Copied to Clipboard!',
            html: 'You can now paste it anywhere you want',
            timer:1000
          })
        }
      })
    },
  },
};
</script>
<style scoped lang="scss">
  .imgContainer {
    display: flex;
    justify-content: center; /* align horizontal */
    align-items: center;
  }

  .bigAvatar {
    display: flex;
    width: 100%;
    max-width: 250px;
    overflow: hidden;
  }

  .centerEverything {
    display: flex;
    text-align: center;
    align-content: flex-start;
    justify-content: center;
    align-items: center;
  }

  .custom-size {
    max-width: calc(100% - 150px) !important;
  }

  .modal-container {
    height: 300px;
    overflow-y: auto;
  }

  .custom-qa-modal > div {
    max-width: 1300px !important;
  }

  .custom-qa-modal > div .va-modal__inner {
    max-width: 1300px !important;
  }

</style>